$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.rootCarousel {
  [class*="baseCardRoot"] {
    display: block;
    min-width: map-get($cardSizes, medium);

    @media screen and (min-width: $breakpoint_l) {
      min-width: map-get($cardSizes, large) + 122px;
    }
  }
}
