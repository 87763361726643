$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.pages {
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
}

.navGroup {
  display: flex;
  gap: $spacing-4;
  border-bottom: $border-1 solid $border-default;
  padding-left: $spacing-6;
  

  @media screen and (max-width: $breakpoint_m) {
    padding-left: $spacing-4;
    overflow: auto;
    @include hide-scrollbar;
  }

  .navOption {
    color: $lights-low;
    background-color: $background-dim;
    margin-right: 10px;

    @include text-buttonL;

    @media screen and (min-width: $breakpoint_l) {
      margin-right: 0;
      padding: $spacing-2 $spacing-1;

      @include text-subtitle2;

      font-weight: 500;

      &:hover {
        color: $lights-high;
        background-color: $background-dim;
        border-bottom: $border-1 solid $accent-default;
      }
    }
  }

  .highlightedNavOption {
    color: $lights-high;
    border-bottom: $border-1 solid $accent-default;
  }
}

.navSeparator {
  position: relative;
  top: -1px;
  border-top: $border-1 solid $border-default;

  left: -$spacing-4;
  width: 100vw;

  @media screen and (min-width: $breakpoint_l) {
    border-top: $border-1 solid $border-default;
    width: 100%;
    left: 0;
  }
}

.tabsSection {
  overflow: hidden;
}

.hidden {
  display: none;
}

.showTabs {
  display: flex;
  align-items: center;
  gap: $spacing-3;
  flex: 1 0 0;
  padding: $spacing-4 $spacing-6 $spacing-4 $spacing-5;
  border-bottom: $border-1 solid $border-default;
}

.allowOverflow {
  overflow: auto;
}

.emptyPageWrapper {
  display: flex;
  justify-content: center;
  margin: $spacing-5 0;

  @media screen and (min-width: $breakpoint_l) {
    margin: $spacing-7 0;
  }
}
