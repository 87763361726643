$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.title {
  position: relative;
  margin-bottom: 26px;
  color: $black;

  @include text-2xl;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 54px;

    @include text-4xl;
  }
}

.rootCarousel {
  & a {
    width: 168px;

    @media screen and (min-width: $breakpoint_l) {
      width: 300px;
    }
  }
}
