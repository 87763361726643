$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_ml) {
    flex-direction: row;
  }
}

.pastLabel {
  position: absolute;
  top: 1px;
  left: 1px;
  padding: 8px 12px;
  background: $white;
  color: $black;
  z-index: 1;

  @include text-xl;
}

.imageWrapper {
  flex-shrink: 0;
  background-color: $black;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  margin: 0 auto;

  @media screen and (min-width: $breakpoint_ml) {
    width: 460px;
    height: 460px;
  }

  @media screen and (min-width: $breakpoint_l) {
    width: 520px;
    height: 520px;
  }

  @media screen and (min-width: $breakpoint_xl) {
    width: 580px;
    height: 580px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    width: 708px;
    height: 708px;
  }
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: $breakpoint_ml) {
    margin-left: 28px;
  }

  @media screen and (min-width: $breakpoint_l) {
    margin-left: 38px;
  }

  @media screen and (min-width: $breakpoint_xl) {
    margin-left: 48px;
  }
}

.content {
  height: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: $breakpoint_ml) {
    margin-top: 0;
  }
}

.meta {
  margin-bottom: 8px;
  color: $dove-gray;

  @include text-xs-medium-uppercase;

  @media screen and (min-width: $breakpoint_ml) {
    margin-bottom: 16;
  }
}

.metaName {
  color: $black;

  @include text-xs-medium-uppercase-underline;
}

.description {
  margin-bottom: 24px;
  color: $black;

  @include text-base-regular;

  @media screen and (min-width: $breakpoint_ml) {
    margin-bottom: 32px;

    @include text-lg-regular;
  }

  a {
    text-decoration: underline;
  }
}

.title {
  margin-bottom: 16px;
  color: $black;

  @include text-3xl;

  @media screen and (min-width: $breakpoint_ml) {
    @include text-4xl;
  }

  @media screen and (min-width: $breakpoint_xl) {
    @include text-5xl;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    @include text-6xl;
  }
}
