$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  em {
    font-family: inherit;
    text-decoration: underline;
    font-style: inherit;
    cursor: pointer;
    font-size: 12px;

    @media screen and (min-width: $breakpoint_l) {
      font-size: 20px;
    }
  }
}

.showLessButton {
  color: $harvest-gold;
  word-wrap: break-word;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  
  @include text-xs-regular;
  font-weight: 500;

  @media screen and (min-width: $breakpoint_l) {

    @include font_size(20px);
  }
}
