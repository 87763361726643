$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.profileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-4;
  margin: $header_height + $spacing-6 $spacing-6 $spacing-4 $spacing-6;

  // Subtract 1px to avoid overflowing into L breakpoint
  @media screen and (max-width: ($breakpoint_l - 1px)) {
    align-items: flex-start;
    flex-direction: column;
    margin: $spacing-4;
    margin-top: $header_height + $spacing-4;
    gap: $spacing-2;
  }
}

.athleteInfo {
  display: flex;
  gap: $spacing-4;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: ($breakpoint_l - 1px)) {
    margin-bottom: $spacing-5;
  }
}

.imageWrapper {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media screen and (max-width: $breakpoint_l) {
    width: 110px;
    height: 110px;
  }

  .image {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    border: 1px solid $border-default;
    object-fit: cover;
    overflow: hidden;

    @media screen and (max-width: $breakpoint_l) {
      width: 110px;
      height: 110px;
    }
  }
}

.headerContent {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: $spacing-4;

  @media screen and (max-width: $breakpoint_l) {
    grid-area: name;
    display: block;
  }
}

.actionButtons {
  gap: $spacing-2;

  @media screen and (max-width: $breakpoint_l) {
    justify-content: center;
    width: 100%;

    & button {
      width: 100%;
    }
  }
}

.athleteName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-2;
}

.socialLinks {
  display: flex;
  gap: $spacing-2;
}

.socialLink {
  width: $spacing-6;
  height: $spacing-6;
  display: flex;
  align-items: center;
  justify-content: center;
}
