$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

$circleSize: 216px;
.root {
  position: relative;
  padding: $spacing-5 $spacing-5 0 $spacing-5;
  margin: $spacing-2 $spacing-4;
  border-radius: $radius-3;
  overflow: hidden;
  max-width: 100vw;
  background-color: $background-default;
  @media screen and (min-width: $breakpoint_l) {
    margin: $spacing-2 $spacing-6;
    // Darker linear
    background: linear-gradient(
      to left,
      rgba(255, 153, 0, 0.8) 0%,
      /* Gradient default (main color) */ mix($background-default, rgba(255, 187, 0, 0.7), 30%) 15%,
      /* Gradient dim */ mix($background-default, rgba(255, 207, 77, 0.4), 50%) 25%,
      /* Gradient bright fading */ mix($background-default, rgba(20, 21, 25, 0.9), 60%) 35%,
      $background-default 45%
    );

    // Linear
    // background: linear-gradient(
    //   to left,
    //   rgba(255, 153, 0, 0.8) 0%,
    //   /* Gradient default (main color) */ rgba(255, 187, 0, 0.7) 15%,
    //   /* Gradient dim */ rgba(255, 207, 77, 0.4) 25%,
    //   /* Gradient bright fading */ rgba(20, 21, 25, 0.9) 35%,
    //   $background-default 45%
    // );

    // Radial Gradient
    // background: radial-gradient(
    //   circle at top right,
    //   rgba(255, 153, 0, 0.8) 0%,
    //   rgba(255, 187, 0, 0.7) 15%,
    //   rgba(255, 207, 77, 0.4) 25%,
    //   rgba(20, 21, 25, 0.9) 35%,
    //   $background-default 45%
    // );

    @include grain-overlay(0.35);
  }
}

.mainBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  z-index: 1;
  text-align: center;

  @media screen and (min-width: $breakpoint_l) {
    text-align: start;
    flex-direction: row;
    padding-bottom: $spacing-5;
  }
}

.title {
  margin-bottom: $spacing-3;
  color: $lights-high;
  text-transform: capitalize;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: $spacing-4;
  }
}

.description {
  color: $lights-high;
  margin-bottom: $spacing-5;
}

.bookContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .desktopPrice {
    display: none;
  }

  & .mobilePrice {
    margin-bottom: $spacing-3;
  }

  & .linkButton {
    margin-bottom: $spacing-4;
  }

  @media screen and (min-width: $breakpoint_l) {
    text-align: start;
    flex-direction: row;
    padding-bottom: $spacing-5;

    & .desktopPrice {
      display: inherit;
      margin-left: $spacing-3;
    }

    & .mobilePrice {
      display: none;
    }

    & .linkButton {
      margin-bottom: 0;
    }
  }
}

.body {
  padding: $spacing-2 0;
  position: relative;
  width: 100%;

  @media screen and (min-width: $breakpoint_l) {
    max-width: 40%;
  }
}

.videoCard {
  margin: 0;
  max-width: $circleSize;

  @media screen and (min-width: $breakpoint_l) {
    margin-right: $spacing-9;
  }
}

.amaVideo {
  position: relative;
  height: $circleSize;
  width: $circleSize;
  border-radius: 100%;
  border: 0;
  background-color: $lights-high;
}

.logoContainer {
  width: 360px;
  position: absolute;
  top: 15%;
  left: 70%;
  z-index: 0;
  opacity: 0.15;
  color: $background-dim;
  transform: scale(1.1);
  pointer-events: none;

  @media screen and (max-width: $breakpoint_l) {
    display: none;
  }
}
