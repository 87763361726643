$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  position: relative;
  padding: $spacing-4 $spacing-4 0 $spacing-4;
  margin: $spacing-4;
  border-radius: $radius-3;
  overflow: hidden;
  max-width: 100vw;
  background-color: $background-default;

  @media screen and (min-width: $breakpoint_l) {
    padding: $spacing-5 $spacing-5 0 $spacing-5;
    margin: $spacing-5 $spacing-6;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: $spacing-4;

  & .seeAllLinkButton {
    display: none;
  }

  @media screen and (min-width: $breakpoint_l) {
    & .seeAllLinkButton {
      display: inherit;
    }
  }
}

.mainBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  z-index: 1;

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
    padding-bottom: $spacing-5;
  }
}

.title {
  margin-bottom: $spacing-3;
  color: $lights-high;
  text-transform: capitalize;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: $spacing-4;
  }
}

.description {
  color: $lights-medium;
  margin-bottom: $spacing-5;
}

.bookContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .linkButton {
    margin-bottom: $spacing-4;
    width: 100%;
  }

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
    padding-bottom: $spacing-5;

    & .seeAllLinkButton {
      display: none;
    }

    & .linkButton {
      width: auto;
      margin-bottom: 0;
    }
  }
}

.body {
  padding: $spacing-2 0;
  position: relative;
  width: 100%;

  @media screen and (min-width: $breakpoint_l) {
    max-width: 60%;
  }
}

.productCard {
  margin: 0;
  width: map-get($cardSizes, large);

  @media screen and (min-width: $breakpoint_l) {
    margin-right: $spacing-6;
  }
}
