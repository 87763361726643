$cdn: 'https://cdn.millions.co';
@import "styles/scss/base";

.title {
  margin: 160px 20px;
  font-weight: $semi_bold_font_weight;
  letter-spacing: -0.1px;
  color: $dove-gray;
  text-align: center;

  @include font_size(28px);
  @include line_height(34px);
}
