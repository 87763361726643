$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";
@import "styles3/scss/base";

.root {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  outline: none;
}

.imageWrapper {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  background-color: $background-default;
  border: $border-default;
  overflow: hidden;
  aspect-ratio: 1;
}

.productImage {
  @include standard_image;
}

.bannerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.banner {
  text-transform: uppercase;
  background-color: $accent-default;
  color: $background-default;
  height: 20px;
  padding: 4px 6px;

  @include font_size(8px);
  font-weight: 500;

  @media screen and (min-width: $breakpoint_m) {
    height: 42px;
    padding: 8px;

    @include font_size(20px);
  }
}

.title {
  margin-top: 8px;
  height: 44px;
  color: $lights-high;
  mix-blend-mode: difference;

  @include line_clamp(2);
  @include text-xs-regular;
  font-weight: 500;

  @media screen and (min-width: $breakpoint_m) {
    margin-top: 15px;

    @include text-2xl;
    text-transform: none;
    font-weight: 500;
  }
}
