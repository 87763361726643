$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  & :global {
    @font-face {
      font-family: GT America;
      src: url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/2022/fonts/GT-America-Standard-Regular.woff2)
          format("woff2"),
        url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/2022/fonts/GT-America-Standard-Regular.woff)
          format("woff");
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: GT America;
      src: url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/2022/fonts/GT-America-Standard-Medium.woff2)
          format("woff2"),
        url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/2022/fonts/GT-America-Standard-Medium.woff)
          format("woff");
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: GT America;
      src: url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/2022/fonts/GT-America-Compressed-Bold.woff2)
          format("woff2"),
        url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/2022/fonts/GT-America-Compressed-Bold.woff)
          format("woff");
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: GT America;
      src: url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/2022/fonts/GT-America-Standard-Black.woff2)
          format("woff2"),
        url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/2022/fonts/GT-America-Standard-Black.woff)
          format("woff");
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: GT America;
      src: url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/2022/fonts/GT-America-Expanded-Black-Italic.woff2)
          format("woff2"),
        url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/2022/fonts/GT-America-Expanded-Black-Italic.woff)
          format("woff");
      font-weight: 900;
      font-style: italic;
      font-display: swap;
    }
    * {
      box-sizing: border-box;
    }
    .book-me-form {
      font-family: GT America;
    }
    .book-me-form h2 {
      font-family: GT America;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 75%;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #000000;
      margin: 0 0 27px;
    }
    .book-me-form label {
      font-weight: 500;
      font-size: 10px;
      line-height: 75%;
      letter-spacing: 0.05em;
      color: #b6b6b6;
      display: inline-block;
      margin-bottom: 12px;
      padding-left: 15px;
    }

    .book-me-form .hs-form-field {
      margin-bottom: 12px;
    }

    .book-me-form .hs-form-field .hs-input {
      border: 1px solid #b6b6b6;
      border-radius: 20px;
      appearance: none;
      width: 100% !important;
      min-height: 47px !important;
      padding: 10px 15px !important;
      font-family: GT America;
    }

    .book-me-form .hs-form-field textarea.hs-input {
      min-height: 146px !important;
    }
    .book-me-form .hs-form-field .input {
      margin: 0 !important;
    }

    .book-me-form fieldset.form-columns-2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .book-me-form fieldset.form-columns-2 .hs-form-field {
      width: calc(50% - 6px) !important;
    }

    .book-me-form .hs-form-field select.hs-input {
      background-image: url(https://19508085.fs1.hubspotusercontent-na1.net/hubfs/19508085/Theme_2021/arrow_select.svg);
      background-repeat: no-repeat;
      background-position: right 10px center;
      padding-right: 50px !important;
      background-size: 30px;
      color: black;
    }

    .book-me-form input.hs-button.primary.large {
      background: #d2ab64;
      height: 47px;
      border-radius: 20px;
      border: 2px solid #d2ab64;
      width: 100%;
      font-family: GT America;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 75%;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000000;
      padding: 17px;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }

    .book-me-form input.hs-button.primary.large:hover {
      background-color: transparent;
    }
    .book-me-form ul.no-list.hs-error-msgs.inputs-list label {
      color: red;
    }

    .book-me-form ul.no-list.hs-error-msgs.inputs-list {
      padding: 0;
      list-style-type: none;
      margin: 0;
    }

    /*     Thank you message css start    */
    .hbspt-form .submitted-message {
      max-width: 554px;
      margin: auto;
      padding: 37px 8px 29px;
      background-color: #fff;
    }
    .hbspt-form .submitted-message p {
      margin: 0px;
    }
    .hbspt-form .submitted-message p:first-child {
      font-size: 32px;
      line-height: 31px;
      letter-spacing: 3px !important;
      padding-bottom: 8px;
    }
    .hbspt-form .submitted-message p {
      font-size: 10px;
      line-height: 17.5px;
      letter-spacing: 1px !important;
    }
    .hbspt-form .submitted-message p:last-child {
      padding-top: 10px;
    }

    strong {
      color: black;
      font-weight: 700;
      font-size: 32px;
      line-height: 96%;
      margin-top: 8px;
    }

    p {
      padding-top: 8px;
      color: #b6b6b6;
    }

    /*     Thank you message css end    */

    @media (max-device-width: 480px) {
      .book-me-form fieldset.form-columns-2 .hs-form-field {
        width: 100% !important;
      }
    }
  }
}
