$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  em {
    &:hover {
      cursor: pointer;
    }
   @include text-buttonL
  }
}

.showLessButton {
  color: $accent-default;
  word-wrap: break-word;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  
  @include text-buttonL

}
