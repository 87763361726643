$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.buttonsWrapper {
  z-index: 2;
  gap: $spacing-2;
  display: flex;

  .actionButtonWrapper {
    flex-grow: 1;
    width: 100%;

    .actionButton {
      display: flex;
      padding: $spacing-2 $spacing-3;
      justify-content: center;
      align-items: center;
      border-radius: $radius-1;
      background: $accent-default;

      @include text-buttonL;
    }

    .textColor {
      color: $lights-high;
    }

    .actionButtonGrayBorder {
      border: 1px solid $lights-low;
    }

    .bookMeButton {
      padding: 2px;
      white-space: nowrap;

      @media screen and (min-width: $breakpoint_l) {
        padding: 5px 12px;
        width: auto;
      }
    }

    .tipMeButton {
      padding: 2px;
      white-space: nowrap;

      @media screen and (min-width: $breakpoint_l) {
        padding: 5px 12px;
        width: auto;
      }
    }
  }

}




.buttonIcon {
  margin-right: 5px;
  height: 30px;
  width: 30px;
}
