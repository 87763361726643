$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  outline: none;
}

.imageWrapper {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  background-color: $white;
  border: $border-light-grey;
  overflow: hidden;
  aspect-ratio: 1;
}

.experienceImage {
  margin: auto;
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
}

.bannerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.banner {
  text-transform: uppercase;
  background-color: $harvest-gold;
  color: black;
  height: 20px;
  padding: 4px 6px;

  @include font_size(8px);
  font-weight: 500;

  @media screen and (min-width: $breakpoint_m) {
    height: 42px;
    padding: 8px;

    @include font_size(20px);
  }
}

.title {
  margin-top: 8px;
  height: 44px;
  color: $black;
  mix-blend-mode: difference;

  @include line_clamp(2);
  @include text-xs-regular;
  font-weight: 500;

  @media screen and (min-width: $breakpoint_m) {
    margin-top: 15px;

    @include text-2xl;
    text-transform: none;
    font-weight: 500;
  }
}
