$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  display: flex;
  flex-direction: row;
  padding: $spacing-6;
  background-color: $background-default;
  border-radius: $radius-3;

  max-width: 360px;
  max-height: 196px;

  padding: $spacing-4;

  @media screen and (min-width: $breakpoint_l) {
    padding: $spacing-7;
    max-width: 730px;
    max-height: 408px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: $spacing-2;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media screen and (min-width: $breakpoint_l) {
      gap: $spacing-4;
      text-align: center;
    }

    .contentHeading {
      width: 100%;

      @include text-h5;

      @media screen and (min-width: $breakpoint_l) {
        @include text-h2;
      }
    }

    .description {
      width: 100%;

      @include text-body2Regular;

      @media screen and (min-width: $breakpoint_l) {
        margin-bottom: $spacing-2;
        @include text-body1Regular18;
      }
    }
  }
}

.imageWrapper {
  width: 100%;
  height: 100%;
  margin-right: $spacing-5;
  border-radius: $radius-2;
  border: $border-1 solid $border-default;

  @media screen and (min-width: $breakpoint_l) {
    margin-right: $spacing-8;
  }
}

.image {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  width: 154px;
  height: 154px;

  // Set image radius to 2
  > span > img {
    border-radius: $radius-2;
  }

  @media screen and (min-width: $breakpoint_l) {
    width: 320px;
    height: 320px;
  }
}

.button {
  @media screen and (max-width: $breakpoint_ml) {
    &.button {
      padding: $spacing-1 $spacing-2;
      border-radius: $radius-0;

      @include text-buttonM;
    }
  }
}
