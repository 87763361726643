$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.athleteContainer {
  overflow: hidden;
}

.relatedProducts {
  margin: 0;
  position: relative;
  max-width: 100vw;
  width: 100%;
  padding: 42px 15px 0;

  @media screen and (min-width: $breakpoint_l) {
    padding: 42px 0 0;
  }
}

.adjustedPosition {
  @media screen and (min-width: $breakpoint_l) {
    padding-left: 120px;
  }
}
