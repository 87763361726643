$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.tabWrapper {
  display: inline-flex;
  gap: $spacing-3;

  .tab {
    display: flex;
    height: 36px;
    padding: $spacing-2 $spacing-3;
    justify-content: center;
    align-items: center;
    gap: $spacing-0;
    border: $border-1 solid $border-default;
    border-radius: $radius-1;

    // Remove highlight tab when scrolling on mobile
    @media screen and (max-width: $breakpoint_m) {
      &:hover {
        background-color: $background-light;
        color: $lights-high;
      }
    }

    &.highlightedTab {
      background-color: $lights-high;
      span {
        color: $background-default;
      }
    }

    @include text-buttonM;
  }

  span {
    text-transform: capitalize;
  }
}
