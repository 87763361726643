$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;

  @media screen and (min-width: $breakpoint_l) {
    gap: $spacing-6;
  }
}

.athleteContainer {
  overflow: hidden;
}

.relatedProducts {
  margin: 0;
  position: relative;
  max-width: 100vw;
  width: 100%;
}

.adjustedPosition {
  padding: $spacing-2 0;

  // Set different paddings for card slider and for header
  [class*="header"] {
    padding: 0 $spacing-4;

    @media screen and (min-width: $breakpoint_l) {
      padding: 0 $spacing-6;
    }
  }

  [class*="body"] {
    padding-left: $spacing-4;
    padding-right: 0;

    @media screen and (min-width: $breakpoint_l) {
      padding-left: $spacing-6;
    }
  }
}
